@import 'variables';

//SubNavItem Variables
$sub-nav-item-default-filter: grayscale(100%) opacity(40%);
$sub-nav-item-padding: 0 16px 8px;
$sub-nav-item-selected-border: 1px solid $main-text-color;
$sub-nav-item-selected-filter: none;
$sub-nav-item-label-font-size: 14px;
$sub-nav-item-padding-bottom: 9px;

.sub-nav-item {
	display: block;
	filter: $sub-nav-item-default-filter;
	padding: $sub-nav-item-padding;

	&--selected {
		border-bottom: $sub-nav-item-selected-border;
		filter: $sub-nav-item-selected-filter;
	}

	&-link {
		display: block;
		text-decoration: none;
	}

	&-label {
		color: $main-text-color;
		display: block;
		font-size: $sub-nav-item-label-font-size;
		font-weight: $medium-font-weight;
		line-height: 1;
		padding-bottom: $sub-nav-item-padding-bottom;
		text-align: left;
		vertical-align: middle;
	}
}
