@import 'variables';

// Volume Level Bar Label Variables
$volume-level-bar-label-font-size: 16px;
$volume-level-bar-label-margin: 0 0 16px;
$volume-level-bar-label-remaining-color: #4a4a4a;
$volume-level-bar-label-remaining-font-size: 12px;

.volume-level-bar-label {
	color: $main-text-color;
	display: block;
	font-size: $volume-level-bar-label-font-size;
	font-weight: $medium-font-weight;
	margin: $volume-level-bar-label-margin;

	&-remaining {
		color: $volume-level-bar-label-remaining-color;
		font-size: $volume-level-bar-label-remaining-font-size;
	}
}
