@import 'variables';
@import 'common';

// Scan View variables
$scan-view-qr-code-width: 85%;
$scan-view-qr-code-text-font-size: 20px;
$scan-view-qr-code-text-margin: 88px auto 0;
$scan-view-qr-code-text-padding: 0 16px;

$scan-view-qr-code-loading-text-font-size: 16px;

$scan-view-bg-max-height: 56vh;
$scan-view-bg-max-width: 145%;
$scan-view-spacer-bottom-max-height: 11vh;

$scan-view-about-link-font-size: 16px;
$scan-view-about-link-height: 48px;
$scan-view-about-link-margin: 8px auto;

.scan-view {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;

  &-qr-code {
    margin: 0 auto;
    width: $scan-view-qr-code-width;
    max-width: 400px;
  }

  &-text {
    color: $main-text-color;
    font-size: $scan-view-qr-code-text-font-size;
    font-weight: $medium-font-weight;
    line-height: 1.2;
    margin: $scan-view-qr-code-text-margin;
    padding: $scan-view-qr-code-text-padding;
    text-align: center;
  }

  &-loading {
    margin: auto;
    position: relative;
    width: 100%;

    &-logo {
      bottom: 50%;
      margin: auto;
      position: absolute;
    }

    &-text {
      @include dotdotdot;
      font-size: $scan-view-qr-code-loading-text-font-size;
      margin-top: 0;
    }
  }

  &-bg {
    display: block;
    max-height: $scan-view-bg-max-height;
    max-width: $scan-view-bg-max-width;
  }

  &--error {
    align-items: center;
    justify-content: space-around;
    overflow: hidden;
    position: relative;

    .error {
      margin-bottom: 0;
      margin-top: 0;
    }

    .error-type {
      margin: 0;
    }
  }

  &-flex-spacer {
    @include flex-spacer;

    &--bottom {
      flex: 2 1;
      max-height: $scan-view-spacer-bottom-max-height;
      overflow: visible;
      position: relative;
      width: 100%;
    }
  }

  &-button-container {
    bottom: calc(100% - #{$scan-view-about-link-height});
    position: absolute;
    width: 100%;

    .button {
      display: block;
    }
  }

  &-about-link {
    color: $main-text-color;
    display: block;
    font-size: $scan-view-about-link-font-size;
    font-weight: $medium-font-weight;
    line-height: 2;
    margin: $scan-view-about-link-margin;
    text-align: center;
    text-decoration: none;
  }

  .error {
    &-type {
      display: none;
    }
  }
}
