@import 'variables';

//Error Page Variables
$error-component-margin: 24px;
$error-type-font-size: 36px;
$error-type-font-weight: bold;
$error-title-font-size: 20px;
$error-title-font-weight: bold;
$error-description-font-size: 16px;


.error {
	margin: $error-component-margin;
	text-align: center;

	&-type {
		color: $main-text-color;
		font-size: $error-type-font-size;
		font-weight: $error-type-font-weight;
	}

	&-title {
		color: $main-text-color;
		font-size: $error-title-font-size;
		font-weight: $error-title-font-weight;
	}

	&-description {
		color: $secondary-text-color;
		font-size: $error-description-font-size;
		white-space: pre-line;
	}
}
