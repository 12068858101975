@keyframes dots {
	0%,
	12.5% {
		opacity: 0;
	}

	25% {
		opacity: 1;
	}

	50% {
		text-shadow: 0.25em 0;
	}

	75%,
	100% {
		text-shadow: 0.25em 0, 0.5em 0;
	}
}

@mixin dotdotdot {
	&::after {
		animation: dots 2s steps(1, end) infinite;
		content: '.';
	}
}


@mixin flex-spacer {
	flex: 1;
	overflow: auto;
}
