@import 'variables';

//VolumeLevelBar Variables
$volume-level-bar-percentage-color: #9b9b9b;
$volume-level-bar-padding-bottom: 14px;
$volume-level-bar-percentage-font-size: 10px;
$volume-level-bar-chart-margin: 0 0 2px 8px;
$volume-level-bar-status-height: 8px;
$volume-level-bar-status-margin-bottom: 2px;
$volume-level-bar-status-width: 8px;

.volume-level-bar {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	padding-bottom: $volume-level-bar-padding-bottom;

	&-bottom-container {
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
	}

	&-percentage {
		color: $volume-level-bar-percentage-color;
		font-size: $volume-level-bar-percentage-font-size;
		font-weight: $medium-font-weight;
		line-height: 1.2;
		text-align: left;
		vertical-align: top;
	}

	&-chart {
		align-items: flex-end;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		margin: $volume-level-bar-chart-margin;
	}

	&-status {
		border-radius: 50%;
		height: $volume-level-bar-status-height;
		margin-bottom: $volume-level-bar-status-margin-bottom;
		width: $volume-level-bar-status-width;

		&--green {
			background-color: #00ca00;
		}
	}

}
