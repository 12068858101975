@import '../App';
@import 'variables';

//Header Variables
$header-height: 128px;
$header-title-font-size: 16px;
$header-title-line-height: 1.5;
$header-back-button-width: 16px;

.header-content {
  &-container {
    width: 100%;
    height: $header-height;
    background: url('../images/header-bg.svg') no-repeat center bottom;
    background-size: 100%;
  
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &-nav {
    width: 100vw;
    height: $header-height;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  @media (min-width: 768px) {
    &-nav {
      height: calc(#{$header-height} - 40px);
      align-self: flex-start;
    }
    
  }

  &-logo {
    img {
      width: 100vw;
      height: $header-height;
    }
  }

  &-back-button {
    /* stylelint-disable-next-line scss/at-extend-no-missing-placeholder */
    @extend .button-as-link;

    box-sizing: content-box;
    width: $header-back-button-width;
    padding: 16px;
    margin: 0;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &-title {
    width: fit-content;
    margin: 0 auto;
    color: $main-text-color;
    font-size: $header-title-font-size;
    font-weight: $medium-font-weight;
    line-height: $header-title-line-height;
    text-align: center;
  }
}
