@import-normalize;

* {
  box-sizing: border-box;
}

.button-as-link {
  background: none;
  border: 0;
  cursor: pointer;
  margin: 0 auto;
  outline: none !important;
  padding: 0;
}

.body {
  background-color: #000;
}

.noscript {
  color: #fff;
}
