@import 'variables';

//Holder Card variables
$holder-card-border-color: #171819;
$holder-card-min-height: 74px;
$holder-card-margin-bottom: 8px;
$holder-card-header-width: 35%;
$holder-card-header-padding: 8px 8px 4px;
$holder-card-ingredient-name-color: #ccc;
$holder-card-ingredient-name-font-size: 12px;
$holder-card-ingredient-name-line-height: 14px;
$holder-card-name-color: #4a4a4a;
$holder-card-name-font-size: 10px;
$holder-card-content-summary-padding: 16px 16px 0;

.holder-card {
  align-items: stretch;
  border: 1px solid $holder-card-border-color;
  border-radius: 2px;
  display: flex;
  flex-direction: row;
  margin-bottom: $holder-card-margin-bottom;
  min-height: $holder-card-min-height;
  width: 100%;
  
  @media (min-width: $desktop-breakpoint) {
    max-width: 33%;
  }

  &-header {
    width: $holder-card-header-width;
    padding: $holder-card-header-padding;
    background: $default-gradient-background;
    border-radius: 2px 0 0 2px;
    border-right: 1px solid $holder-card-border-color;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &-ingredient-name {
    color: $holder-card-ingredient-name-color;
    font-size: $holder-card-ingredient-name-font-size;
    font-weight: $medium-font-weight;
    line-height: $holder-card-ingredient-name-line-height;
    margin: 0;
  }

  &-name {
    color: $holder-card-name-color;
    font-size: $holder-card-name-font-size;
    font-weight: $medium-font-weight;
    margin: 0;

    &:empty::before {
      content: ' ';
      white-space: pre;
    }
  }

  &-content {
    width: 100% - $holder-card-header-width;
    align-items: stretch;
    display: flex;

    &-summary {
      width: 100%;
      padding: $holder-card-content-summary-padding;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }
}
