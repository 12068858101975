@font-face {
	font-display: swap;
	font-family: Unity;
	font-weight: normal;
	src: url('./fonts/TCCC-UnityText-Regular.woff') format('woff');
}

@font-face {
	font-display: swap;
	font-family: Unity;
	font-weight: 500;
	src: url('./fonts/TCCC-UnityText-Medium.woff') format('woff');
}


body {
	font-family: Unity, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
		'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
		sans-serif;

	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	height: 100vh;
	margin: 0;
}
