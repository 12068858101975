@import 'variables';

//Holder List variables
$holder-list-padding: 16px;

$holder-list-empty-message-font-size: 16px;
$holder-list-empty-message-margin: 16px 0;

.holder-list {
  align-items: stretch;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: $holder-list-padding;

  &-empty-message {
    color: $main-text-color;
    font-size: $holder-list-empty-message-font-size;
    margin: $holder-list-empty-message-margin;
  }

  @media (min-width: $desktop-breakpoint) {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
  }
}
