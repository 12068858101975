//Volume Level Bar Item variables
$bar-chart-item-default-bg: #171819;
$bar-chart-item-green-bg: #1bcf1b;
$bar-chart-item-red-bg: #f00;
$bar-chart-item-yellow-bg: #f8d51c;
$bar-chart-item-height: 5px;
$bar-chart-item-margin-left: 2px;
$bar-chart-item-width: 14px;

.volume-level-bar-chart-item {
	background-color: $bar-chart-item-default-bg;
	height: $bar-chart-item-height;
	margin-left: $bar-chart-item-margin-left;
	width: $bar-chart-item-width;

	&--green {
		background-color: $bar-chart-item-green-bg;
	}

	&--red {
		background-color: $bar-chart-item-red-bg;
	}

	&--yellow {
		background-color: $bar-chart-item-yellow-bg;
	}
}
