@import 'variables';

.ot-sdk-show-settings {
	background: none;
	border: 0 !important;
	color: $main-text-color !important;
	display: block;
	font-family: $font-family-unity-with-fallback;
	font-size: 16px !important;
	font-weight: $medium-font-weight;
	padding: 0 !important;
}
