@import 'variables';

//Dispenser Card Variables
$dispenser-card-border-color: #2c2c2e;
$dispenser-card-margin-bottom: 16px;
$dispenser-card-header-bg-gradient: $default-gradient-background;
$dispenser-card-header-padding: 12px 16px;
$dispenser-card-status-size: 24px;
$dispenser-card-status-normal-color: #00ca00;
$dispenser-card-status-not-available-color: #3f4044;
$dispenser-card-header-name-margin: 8px 16px;
$dispenser-card-header-name-font-size: 16px;
$dispenser-card-content-padding: 16px;
$dispenser-card-content-min-height: 176px;
$dispenser-card-content-img-max-width: 92px;

.dispenser-card {
	border: 1px solid $dispenser-card-border-color;
	border-radius: 2px;
	margin-bottom: $dispenser-card-margin-bottom;

	&-header {
		align-items: center;
		background: $dispenser-card-header-bg-gradient;
		display: flex;
		flex-direction: row;
		padding: $dispenser-card-header-padding;
	}

	&-status {
		border-radius: 50%;
		height: $dispenser-card-status-size;
		width: $dispenser-card-status-size;

		&--not-available {
			background-color: $dispenser-card-status-not-available-color;
		}

		&--normal {
			background-color: $dispenser-card-status-normal-color;
		}
	}

	&-name {
		color: $main-text-color;
		flex-grow: 1;
		font-size: $dispenser-card-header-name-font-size;
		font-weight: 500;
		line-height: 1;
		margin: $dispenser-card-header-name-margin;
	}

	&-arrow {
		flex-shrink: 1;
	}

	&-content {
		align-items: stretch;
		display: flex;
		flex-direction: row;
		min-height: $dispenser-card-content-min-height;
		padding: $dispenser-card-content-padding;

		&-summary {
			color: $main-text-color;
			flex-grow: 1;

			p {
				margin: 0;
			}
		}

		&-aside {
			align-items: flex-end;
			display: flex;
			flex-shrink: 1;
			max-width: $dispenser-card-content-img-max-width;

			img {
				max-height: $dispenser-card-content-min-height - 2 * $dispenser-card-content-padding;
				max-width: 100%;
			}
		}
	}

	&--loading {
		.dispenser-card-header,
		.dispenser-card-content {
			padding: 0;
		}
	}

}
