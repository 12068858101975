@import 'variables';

//Dispenser List Variables
$dispenser-list-margin-horizontal: 16px;
$dispenser-list-title-margin: 24px 8px;
$dispenser-list-title-font-size: 20px;
$dispenser-list-title-font-weight: 500;

$dispenser-list-empty-message-font-size: 16px;
$dispenser-list-empty-message-margin: 24px 8px;


.dispenser-list {
	padding: 0 $dispenser-list-margin-horizontal;
	width: 100%;

	&-title {
		color: $secondary-text-color;
		font-size: $dispenser-list-title-font-size;
		font-weight: $dispenser-list-title-font-weight;
		margin: $dispenser-list-title-margin;

		&--error {
			font-size: 16px;
			font-weight: bold;
			text-align: center;
		}
	}

	&-empty-message {
		color: $main-text-color;
		font-size: $dispenser-list-empty-message-font-size;
		margin: $dispenser-list-empty-message-margin;
	}

}
