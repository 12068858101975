@import 'variables';

//Header Variables
$header-gradient: linear-gradient(0, #1e1e1e, #0d0d0d);
$header-height: 120px;
$header-title-font-size: 16px;
$header-title-line-height: 1.5;
$header-back-button-left: 16px;
$header-back-button-width: 16px;

.header {
	background: $main-bg-color;
	position: relative;
	width: 100%;
}
