@import 'variables';

//Button Variables
$button-background: linear-gradient(90deg, #5b5d63, #47494d);
$button-border-radius: 8px;
$button-border-color: #7c7e85;
$button-text-font-size: 16px;
$button-padding: 8px 16px;
$button-width: 70%;

.button {
	background: $button-background;
	border: 1px solid $button-border-color;
	border-radius: $button-border-radius;
	color: $main-text-color;
	font-family: $font-family-unity-with-fallback;
	font-size: $button-text-font-size;
	font-weight: $medium-font-weight;
	line-height: 2;
	margin: auto;
	padding: $button-padding;
	text-align: center;
	width: $button-width;
}
