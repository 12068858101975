@import 'variables';
@import 'common';

$welcome-header-padding-bottom: 8px;
$welcome-title-font-size: 34px;
$welcome-description-font-size: 16px;
$welcome-description-padding-top: 16px;

$welcome-bg-max-height: 56vh;
$welcome-bg-max-width: 145%;

$welcome-about-link-font-size: 16px;
$welcome-about-link-height: 48px;
$welcome-about-link-margin: 8px auto;

$welcome-button-max-width: 430px;


.welcome {
	align-items: center;
	display: flex;
	flex-direction: column;
	height: 100%;
	justify-content: space-around;
	overflow: hidden;
	position: relative;

	&-header {
		margin-top: 0;
		padding-bottom: $welcome-header-padding-bottom;
		width: 100%;
	}

	&-title {
		color: $main-text-color;
		font-size: $welcome-title-font-size;
		font-weight: $medium-font-weight;
		margin: 0;
		text-align: center;
		vertical-align: middle;
	}

	&-description {
		color: $main-text-color;
		font-size: $welcome-description-font-size;
		padding-top: $welcome-description-padding-top;
		text-align: center;
		vertical-align: middle;

		&-text {
			margin: auto;
			width: 83%;
		}
	}

	&-bg {
		display: block;
		max-height: $welcome-bg-max-height;
		max-width: $welcome-bg-max-width;
	}

	&-flex-spacer {
		@include flex-spacer;

		&--bottom {
			flex: 2;
			max-height: 11vh;
			overflow: visible;
			position: relative;
			width: 100%;
		}

		&--top {
			max-height: 13vh;
		}
	}

	&-nav {
		align-items: center;
		bottom: calc(100% - #{$welcome-about-link-height});
		display: flex;
		flex-direction: column;
		justify-content: center;
		position: absolute;
		width: 100%;

		&-privacy,
		&-about-link {
			color: $main-text-color;
			display: block;
			font-size: $welcome-about-link-font-size;
			font-weight: $medium-font-weight;
			line-height: 2;
			margin: $welcome-about-link-margin;
			text-align: center;
			text-decoration: none;
		}

		.button {
			max-width: $welcome-button-max-width;
		}
	}
}
