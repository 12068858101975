.dispenser-view {
	padding-bottom: 40px;

	.floating-button {
		height: 40px;
		width: 40px;

		svg {
			display: block;
			height: 100%;
			object-fit: contain;
			width: 100%;
		}
	}
}
