// Breakpoints
$tablet-breakpoint: 768px;
$desktop-breakpoint: 1024px;

//Color Pallete Variables
$main-bg-color: #000;
$main-text-color: #fff;
$secondary-text-color: #eee;
$default-gradient-background: linear-gradient(180deg, #151515, #111);
$medium-font-weight: 500;
$font-family-unity-with-fallback: Unity, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
