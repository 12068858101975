@import 'variables';

//Holder Issue variables
$holder-card-content-issue-message-font-size: 20px;
$holder-card-content-issue-message-font-weight: bold;
$holder-card-content-issue-message-padding: 4px 0 20px;
$holder-card-content-issue-message--not-available-color: #5b5d63;
$holder-card-content-issue-message--prime-needed-color: #fcb135;
$holder-card-content-issue-message--soldout-color: #f00;

.holder-card-content-issue-message {
  font-size: $holder-card-content-issue-message-font-size;
  font-weight: $holder-card-content-issue-message-font-weight;
  margin: 0;
  padding: $holder-card-content-issue-message-padding;
  text-align: left;

  @media (min-width: $desktop-breakpoint) {
    font-size: 16px;
  }

  &--not-available {
    color: $holder-card-content-issue-message--not-available-color;
  }

  &--prime-needed,
  &--purge-needed {
    color: $holder-card-content-issue-message--prime-needed-color;
  }

  &--soldout {
    color: $holder-card-content-issue-message--soldout-color;
  }
}
