@import 'variables';

.floating-button {
	background: #1bcf1b;
	border-radius: 50%;
	border-style: none;
	bottom: 20px;
	color: $main-text-color;
	fill: $main-text-color;
	font-family: $font-family-unity-with-fallback;
	font-size: 16px;
	font-weight: $medium-font-weight;
	margin: auto;
	padding: 10px;
	position: absolute;
	right: 20px;
	text-align: center;
}
