//SubNav Variables
$subnav-padding: 24px 0 0;

.sub-nav {
	display: flex;
	flex-direction: row;
	list-style: none;
	margin: 0;
	overflow-x: auto;
	padding: $subnav-padding;

}
